.form__extraordinary {
  display: flex;
  flex-direction: column;
  margin: auto;
  gap: 8px;
  width: 400px;
  padding: 24px 0;
  &__footer {
    background: #eaf3f6;
    border-radius: 0 0 20px 20px;
    padding: 24px;
    display: flex;
    gap: 16px;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    width: 100%;
    button {
    border-radius: 28px;
    height: 54px;
    }
  }
  > div > div > div:first-child {
    font-weight: 700;
  }
  &__select > div {
    border-radius: 8px !important;
  }
}

@media only screen and (max-width: 500px) {
  .modal__extraordinary {
    width: calc(100% - 48px);
    padding: 24px 0 8px;
    margin: 0 24px;
  }
}
