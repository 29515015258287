.building {
  &__header {
    display: flex;
    align-items: center;
    width: 100%;
    column-gap: 16px;
    row-gap: 10px;
    padding: 8px 0;
    flex-wrap: wrap;
    &__search {
      display: flex;
      gap: 24px;
      align-items: center;
      &__input {
        width: 295px;
        height: 36px;
        border: 1px solid #bfbfbf;
        border-radius: 20px;
        background: #ffffff;
        padding: 6px 12px;
      }
    }
    &__button {
      width: 140px !important;
      height: 38px !important;
      border: 1px solid #237804;
      border-radius: 20px;
    }
  }
  &__content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-end;
    padding: 0 24px;
  }
  &__wrap__item {
    margin: 24px 0;
    display: flex;
    gap: 24px;
    flex-wrap: wrap;
    width: 100%;
    height: 100%;
  }
  .group__pagination {
    display: flex;
    column-gap: 16px;
    align-items: center;
  }
}

@media only screen and (max-width: 1000px) {
  .building {
    &__header {
      &__search {
        &__input {
          width: 100%;
          min-width: 200px;
        }
      }
    }
  }
}
