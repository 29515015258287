.modal__footer {
  background: #eaf3f6;
  border-radius: 0 0 20px 20px;
  padding: 24px;
  display: flex;
  gap: 16px;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  width: 100%;

  button {
    width: 172px;
    border-radius: 28px;
    height: 40px;
    gap: 10px
  }
}

.invoice__deatail {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 16px;
  width: 327px;
  background: #F5F5F5;
  border-radius: 8px;
  margin: 0 auto;
  justify-content: flex-start;
}

.modal__body {
  height: 663px;
  overflow-y: auto;
  padding: 16px 0;
}
