.modal__meterType {
  display: flex;
  flex-direction: column;
  margin: auto;
  gap: 8px;
  width: 400px;
  padding: 24px 0 0;

  &__label {
    line-height: 150%;
    position: relative;
    display: inline-flex;
    align-items: center;
    max-width: 100%;
    color: rgba(0, 0, 0, 0.85);
    font-weight: 700;
  }
  
  &__option {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 8px;
  }

  .form__item--full {
    min-width: 100%;
    margin: 0;
    padding-bottom: 0;

    input {
      padding: 8px;
      height: 37px;
      border-radius: 8px;
    }
  }
}

@media only screen and (max-width: 500px) {
  .modal__meterType {
    width: calc(100% - 48px);
    padding: 24px 0 8px;
    margin: 0 24px;
  }
}