.form__container {
  padding: 24px 24px 12px 24px;
}

.form__top {
  display: flex;
  width: 100%;
  flex-wrap: wrap;
}

.form__uploadimage {
  display: flex;
  width: 160px;
}

.form__name {
  flex: 1;
  margin-left: 24px;
  display: flex;
  flex-wrap: wrap;
  column-gap: 40px;
  > div {
    margin-bottom: 19px;
    flex: 1;
  }
  > div > div > div:first-child {
    padding: 0 0 4px;
    font-weight: 700;
    font-size: 14px;
  }
}

.form__bottom {
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  column-gap: 40px;
  margin-top: 12px;
  > div {
    font-size: 14px;
    font-weight: 700;
    flex: 1;
    > div > div {
      padding-bottom: 0 0 4px;
    }
  }
}

.form__footer {
  background: #eaf3f6;
  border-radius: 0 0 20px 20px;
  padding: 24px;
  display: flex;
  gap: 16px;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  width: 100%;
  > div {
    flex: 1;
    max-width: 328px;
  }
  button {
    width: 100%;
  }
}

.form__item--full {
  min-width: 100%;

  > div {
    padding-left: 0 !important;
  }
}

.form__item__placeholder {
  font-size: 14px;
  font-weight: 400;
  color: #bfbfbf;
  height: 40px;
  align-items: center;
  display: flex;
}

.form__item--half {
  width: 100%;
  min-width: 260px;
}
.input__number > div {
  background: none;
}
@media only screen and (max-width: 530px) {
  .form {
    &__top {
      flex-direction: column;
      align-items: center;
    }
    &__uploadimage {
      margin: 24px 0;
      > span {
        padding: 0;
        border-right: none;
      }
    }
    &__name {
      margin: 0;
    }
    &__bottom {
      margin: 0;
    }
  }
}

@media only screen and (max-width: 815px) {
  .form__name:nth-child(3) {
    margin-left: 0;
  }
  .form__item--half {
    width: 100%;
    min-width: 280px;
  }
}
