.header {
  background: linear-gradient(to bottom, #94e0ee -43.21%, #2f6e96 47.96%, #39507d 83.6%, #423768 135.72%);
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5px 50px;
  height: 156px;

  &__menu {
    width: 1280px;
    ul {
      background: transparent;
      border-bottom: none;
      justify-content: center;
      li {
        line-height: 59px;
        font-size: 16px;
        svg {
          opacity: 0.5;
        }
        a {
          font-weight: 300;
          opacity: 0.5;
          color: var(--color-daybreak-blue) !important;
        }
      }
      li::after {
        left: 45px !important;
        bottom: 12px !important;
      }
    }
  }
  &__icon {
    display: inline-block;
  }
  &__logo {
    color: var(--color-daybreak-blue);
    font-size: 24px;
    font-weight: 900;
    text-transform: uppercase;
    cursor: pointer;
    white-space: nowrap;
  }
}
.header__mobi-btn {
  display: none;
}

.drawer {
  > div:nth-child(3) {
    width: 200px !important;
  }
  > div > div {
    background-color: #2f6e96;
    ul {
      background-color: transparent;
      border-right: none;
    }
  }
}

@media only screen and (max-width: 600px) {
  .header {
    padding: 5px 20px;
    &__menu {
      display: none;
    }
    &__mobi-btn {
      display: block;
    }
  }
}

@media only screen and (max-width: 1430px) {
  .header__menu {
    width: 60%;
  }
}
