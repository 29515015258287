.controls__container {
  display: flex;
  align-items: center;
  column-gap: 24px;
}

.btn__filter {
  width: 36px !important;
  height: 36px !important;
  padding: 0 !important;
  border-radius: 8px !important;
}

.btn__add {
  > div {
    margin-right: 12px;
  }
}

.page__content__filter {
  display: flex;
  row-gap: 10px;
  align-items: center;
  flex-wrap: wrap;
  margin: 24px 0;
  column-gap: 16px;
  padding: 0 30px;
  max-width: 1280px;
  margin: 24px auto 24px auto;
  &__userType {
    display: flex;
    align-items: center;
    column-gap: 16px;
    margin-right: auto;
    .icon__filter {
      margin-right: 8px;
    }
    > div {
      > div {
        // background: #f5fdff !important;
        border-radius: 24px !important;
        > span:nth-child(2) {
          display: flex;
          align-items: center;
          .icon__filter {
            margin-right: 8px;
          }
        }
      }
    }
  }
  &__printQR {
    display: flex;
    align-items: center;
    column-gap: 16px;
    margin-left: auto;
  }
}

.printqrcode__btn {
  width: 170px !important;
  font-size: 16px;
  font-weight: 500;
  > div {
    margin-right: 8px;
  }
}

.addstaff__btn {
  width: 160px !important;
  height: 38px !important;
  font-size: 16px;
  font-weight: 500;
  > div {
    margin-right: 8px;
  }
}

.list__qrcode {
  position: absolute;
  z-index: -2;
  top: 0;
  display: flex;
  width: 774px;
  flex-wrap: wrap;
  margin-top: 24px;
  align-items: center;
  margin-left: 25px;
  > div {
    width: calc(774px / 5 - 10px);
    height: calc(1139px / 7);
    display: flex;
    align-items: center;
    // justify-content: flex-start;
    flex-direction: column;
    > p {
      width: calc(774px / 5 - 20px);
      word-break: break-all;
      text-align: center;
      font-size: 12px;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
      overflow: hidden;
      display: -webkit-box;
    }
  }
}

.search {
  background: #fff;
  border-radius: 20px;
  min-width: 200px;
  width: 100%;
  height: 36px;
  border: 1px solid #bfbfbf;
  border-radius: 20px;
  padding: 6px 12px;
}
.cardstaff__wrap {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 1280px;
  padding: 0 18px;
  margin: 0 auto;
}

.cardstaff__container {
  width: 100%;
}

.page__container {
  width: 100%;
}

.pagination {
  margin-top: 16px;
  width: 100%;
  display: flex;
  justify-content: end;
}

.control__wrap {
  column-gap: 16px;

  width: 100%;
  > div:first-child {
    max-width: 300px;
    width: 100%;
  }
}
