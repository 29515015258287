.tableAction {
  padding: 0;
  border: 0;
  color: #1890FF;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  cursor: pointer;

  &:hover {
    box-shadow: none;
    color: #1890FF;
    background: transparent;
  }
}

.pagination {
  margin-top: 16px;
  text-align: right;
  width: 100%;
  display: flex;
  justify-content: flex-end;
}

.page__content {
  max-width: 1280px;
  margin: 0 auto;

  .empty__results {
    padding: 40px 24px;
  }

  ul:nth-child(3) {
    display: flex;
    justify-content: end;
    margin-top: 24px;
  }

  .isOpen {
    background: var(--color-primary-2) !important;
  }

  .threeDot {
    cursor: pointer;
    border-radius: 4px;
    width: 22px;
    height: 24px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }
}