body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #000;
}

.pageContent {
  .ant-tabs-tab {
    height: 38px;
    margin-bottom: 4px !important;
    margin-left: 8px !important;
    padding: 8px 32px !important;
    background: #D9D9D9 !important;
    border-radius: 8px 8px 0px 0px !important;
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 22px;
    color: #8C8C8C !important;

    .ant-tabs-tab-btn {
      color: #8C8C8C;
      min-width: max-content;
    }
  }

  .ant-tabs-tab-active {
    background: linear-gradient(156.45deg,
        #BDF0F9 7.32%,
        #B8EEF8 12.01%,
        #ABE9F4 17.34%,
        #94E0EE 23%,
        #2F6E96 76.21%,
        #39507D 97.01%,
        #403E6E 114.78%,
        #423768 127.43%) !important;
    .ant-tabs-tab-btn {
      color: #fff !important;
    }
  }
}