.settingForm {
  &__control--container {
    background: #ffffff;
    border-radius: 16px;
    max-width: 768px;
    min-width: 264px;
    padding: 24px;
    margin: 0 auto;
    margin-top: 120px;
    margin-bottom: 24px;
    > div:first-child {
      margin-bottom: 10px;
    }
  }

  &__item {
    display: flex;
    width: 100%;
    flex-direction: column;
    min-width: 160px;
    margin-bottom: 0;

    label {
      font-size: 14px;
      font-weight: 700;
      color: #27272a;
    }
  }

  &__itemDisable {
    label {
      color: #d9d9d9;
    }
  }

  &__btn--full {
    max-width: 768px;
    margin: 0 auto;
    width: 100%;
    border-radius: 28px;
    border-width: 1px;
    border-color: #237804;
    color: #389e0d;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 40px;
    letter-spacing: -2px;

    &:hover {
      box-shadow: 0px 6px 16px 0px #2378044d;
    }
  }

  &__btn {
    &__wrap {
      max-width: 768px;
      margin: 0 auto;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      gap: 8px;

      > div {
        flex: 1;
      }

      button {
        width: 100%;
        min-width: 150px;
      }
    }
  }
}
