.manager {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 16px;
  gap: 10px;
  height: 220px;
  background: rgba(255, 255, 255, 0.9);
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.06);
  border-radius: 8px;
  cursor: default;

  line-height: 24px;
  text-align: left;
}

.test {
  display: flex;
  column-gap: 24px;
  align-items: center;
}

.top {
  display: flex;
  width: 100%;
  padding-bottom: 8px;
  border-bottom: 1px solid #d9d9d9;
  .managerInfor {
    display: flex;
    flex-direction: column;
    margin-left: 8px;
    flex-shrink: 0;
    width: calc(100% - 80px);
    min-width: 100px;
    > span:first-child {
      font-size: 18px;
      font-weight: 600;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
    > span:nth-child(2) {
      font-size: 16px;
      font-weight: 400;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }
  .dropdown {
    margin: 0 4px 28px auto;
  }
}
.bottom {
  width: 100%;
  display: flex;
  flex-direction: column;
  flex: 1;
  row-gap: 10px;
  &__information {
    display: flex;
    flex-direction: column;
    .manager__information {
      display: flex;
      justify-content: space-between;
      &__title {
        font-weight: bold;
        min-width: 126px;
      }
      &__content {
        width: 100%;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }
    &__more {
      border-radius: 50% !important;
      width: 24px;
      padding: 0 !important;
      font-size: 12px;
      text-align: center;
      flex: none !important;
    }
    > span:not(:first-child) {
      flex: 1;
    }
  }
  .building,
  .status {
    display: flex;
    > span:first-child {
      font-weight: 600;
      text-align: left;
      display: inline-block;
      width: 64px;
    }
    > span:not(:first-child) {
      background: var(--color-gray);
      margin-right: 8px;
      display: inline-block;
      padding: 0px 8px;
      border-radius: 2px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
}

.isopen {
  background: var(--color-primary-2) !important;
}

.threedot {
  background: #fff;
  border-radius: 4px;
  width: 22px;
  height: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

@media only screen and (max-width: 625px) {
  .addstaff__container {
    max-width: 350px;
  }
}
.icon {
  &__wrap {
    height: 24px;
    width: 50px;
    cursor: pointer;
  }
}
