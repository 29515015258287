.editForm {

  &__control--container {
    background: #ffffff;
    border-radius: 16px;
    max-width: 768px;
    min-width: 264px;
    padding: 24px;
    margin: 0 auto;
    margin-top: 120px;
    margin-bottom: 24px;
  }

  &__item {
    display: flex;
    width: 400px;
    flex-direction: column;
    min-width: 160px;
    margin: auto;
    margin-bottom: 0;
    padding: 24px 0;

    label {
      font-size: 14px;
      font-weight: 700;
      color: #27272A;
    }
  }

  &__btn {
    &__wrap {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      background: #EAF3F6;
      padding: 24px;
      gap: 16px;
      border-radius: 0 0 24px 24px;

      > div {
        flex: 1;
      }

      button {
        width: 100%;
        min-width: 150px;
      }
    }
  }
}
