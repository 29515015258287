.pagination {
  display: flex;
  align-items: center;
  column-gap: 16px;
  &__wrapper {
    display: flex;
    align-items: center;
    column-gap: 6px;
    padding: 5px 12px;
    border-radius: 6px;
    background-color: #ffff;
    > span {
      cursor: pointer;
    }
  }
}
