.form {
  width: 100%;
  label {
    font-weight: 700;
    font-size: 14px;
  }
  &__item {
    > div:nth-child(2) {
      position: relative;
    }
    &__edit {
      position: absolute;
      right: 10px;
      top: 0;
      right: 0;
      width: 30px;
      height: 100%;
      display: flex;
      align-items: center;
      cursor: pointer;
      svg {
        width: auto;
        height: auto;
      }
    }
  }
  &__avatar {
    padding-bottom: 16px;
    display: flex;
    &__upload {
      border-radius: 50%;
    }
  }
  &__btn {
    display: flex;
    justify-content: center;
    width: 100%;
    > div {
      margin-top: 16px;
      width: 100%;
      > button {
        width: 100%;
      }
    }
  }
  > div:not(:first-child) {
    margin-bottom: 16px;
  }
}

.form__avatar {
  > span {
    padding-right: 0 !important;
  }
}

.content {
  display: flex;
  justify-content: center;
  max-width: 600px;
  min-width: 300px;
  margin: 20px auto 0 auto;
}

.form__control--container {
  background: #ffffff;
  border-radius: 16px;
  max-width: 600px;
  min-width: 264px;
  padding: 24px;
  margin: 0 16px;
}

.isview {
  cursor: not-allowed;
}

.form__item--full {
  width: 100%;
  input[disabled] {
    cursor: default !important;
  }
}

.form__item--half {
  margin-bottom: 16px;
  input[disabled] {
    cursor: default !important;
  }
}

.form__btn {
  display: flex;
  column-gap: 16px;
  margin: 0 16px;
  width: calc(100% - 32px);
}
