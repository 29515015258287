.createFormula {

  &__header {
    display: flex;
    align-items: center;
    width: 100%;
    column-gap: 16px;
    row-gap: 10px;
    padding: 8px 0;
    flex-wrap: wrap;

    &__search {
      display: flex;
      gap: 24px;
      align-items: center;

      &__input {
        width: 295px;
        height: 36px;
        border: 1px solid #bfbfbf;
        border-radius: 20px;
        background: #ffffff;
        padding: 6px 12px;
      }
    }

    &__button {
      width: 140px !important;
      height: 38px !important;
      border-radius: 20px;
      box-shadow: 0px 6px 16px rgba(35, 120, 4, 0.3);
      background: var(--color-primary-3);
      color: #ffffff;
    }
  }

  &__content {
    max-width: 1280px;
    padding: 15px 24px 0 24px;
    margin: 0 auto;

    &__item {
      display: flex;

      &__label {
        font-size: 14px;
        font-weight: 700;
        min-width: 200px;
        margin: 10px 0px;
      }

      &__input {
        width: 100%;

        > div:first-child {
          margin-bottom: 19px;
        }
      }

      &__bottom {
        display: flex;
        align-items: center;

        &__body {
          display: flex;
          width: 100%;
          justify-content: space-between;

          &__left {
            width: 100%;
            display: flex;
            gap: 10px;

            > div > div {
              margin-bottom: 0;
            }
          }

          &__right {
            display: flex;
            justify-content: flex-end;
          }
        }
      }
    }
  }

  &__line {
    min-width: 100%;
    border-top: 4px solid #39507d;
    margin-bottom: 15px;
  }

  &__body {
    display: flex;
    flex-direction: column;
  }

  &__addRule__btn {
    width: 140px !important;
    font-size: 16px;
    font-weight: 500;

    > div {
      margin-right: 8px;
    }
  }

  &__tabs {
    max-width: 1280px;
    height: 100%;
    padding: 15px 24px 0 24px;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    table > tbody > tr > td {
      padding: 16px 16px 0px 16px;
    }
  }

  &__scope {
    display: flex !important;
    justify-content: space-between;
    align-items: center;

    > div {
      flex: 1;
    }

    &__space {
      margin-right: 5px !important;
      margin-left: 5px !important;
    }
  }

  &__stt {
    margin-bottom: 23px;
  }

  &__groupBtn {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 15px;
    margin-bottom: 23px;

    div {
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 30px;
      height: 30px;
    }
  }

  &__panel {
    padding: 8px 40px;
    border-radius: 8px 8px 0 0;
    font-size: 16px;
    line-height: 24px;
  }

  &__active {
    color: #ffffff;
    background: linear-gradient(
      156.45deg,
      #bdf0f9 7.32%,
      #b8eef8 12.01%,
      #abe9f4 17.34%,
      #94e0ee 23%,
      #2f6e96 76.21%,
      #39507d 97.01%,
      #403e6e 114.78%,
      #423768 127.43%
    );
  }

  &__error {
    color: red;
  }

  &__table {

    &__add {
      cursor: pointer;
      padding: 10px;
      background: #f5c13b;
    }

    &__note {
      display: flex;
      gap: 20px;
      margin-top: 15px;

      div {
        font-size: 14px;
        font-weight: 600;
      }
    }
  }

  &__footer {
    border-radius: 0 0 20px 20px;
    display: flex;
    gap: 16px;
    margin-top: 15px;
    margin-bottom: 4px;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    width: 100%;

    > div {
      flex: 1;
      max-width: 140px;
    }

    button {
      width: 100%;
    }
  }

  &__addColumn {
    border-radius: 0 0 20px 20px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: wrap;
    width: 100%;

    > div {
      flex: 1;
      max-width: 140px;
    }

    button {
      display: flex;
      gap: 8px;
      width: 100%;
    }
  }

  &__errorTabMain {
    font-size: 12px;
    font-weight: 600;
    color: #ff4d4f;
    min-height: 19px;
  }
}

.form__item--full {
  min-width: 100%;
}

.form__item--half {
  width: 100%;
  max-width: 300px;
}

.pagination__container {
  display: flex;
  justify-content: end;
  width: 100%;
}

.ant-select-dropdown {
  width: 400px;
}
