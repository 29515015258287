.sort {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  gap: 16px;

  &__icons {
    display: grid;
  }
}
