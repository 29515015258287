.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  min-width: 200px;
  min-height: 200px;
}

.form__content {
  padding: 48px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 24px;

  p {
    font-size: 24px;
    line-height: 36px;
    font-weight: 400;
    color: #808089;
  }
}

.form__footer {
  background: #eaf3f6;
  border-radius: 0 0 20px 20px;
  display: flex;
  gap: 8px 16px;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  width: 100%;
  padding: 24px;
  > div {
    flex: 1;
    > button {
      width: 100%;
      min-width: 112px;
    }
  }
}