.contract_person {
  &__header {
    display: flex;
    align-items: center;
    width: 100%;
    column-gap: 16px;
    row-gap: 10px;
    padding: 8px 0;
    flex-wrap: wrap;

    &__search {
      display: flex;
      gap: 24px;
      align-items: center;
      &__input {
        width: 295px;
        height: 36px;
        border: 1px solid #bfbfbf;
        border-radius: 20px;
        background: #ffffff;
        padding: 6px 12px;
      }
      &__btn {
        width: 36px !important;
        height: 36px !important;
        border-radius: 8px !important;
        padding: 0;
      }
    }
    &__button {
      width: 140px !important;
      height: 38px !important;
      border: 1px solid #237804;
      border-radius: 20px;
    }
  }
  .page__content {
    max-width: 1280px;
    padding: 0 24px;
    margin: 0 auto;
    .empty__results {
      padding: 40px 24px;
    }

    &__filter {
      display: flex;
      row-gap: 10px;
      flex-wrap: wrap;
      margin: 24px 0;
      column-gap: 16px;
      padding: 0 24px;
      max-width: 1280px;
      margin: 24px auto;
      justify-content: space-between;
      > div {
        border: none;
        border-radius: 8px;
        border-color: transparent !important;
        box-shadow: none !important;
        div {
          background: transparent !important;
          border-color: transparent !important;
          box-shadow: none !important;

          > span:nth-child(2) {
            align-items: center;
            flex: auto;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            .icon__filter {
              margin-right: 8px;
              margin-bottom: 3px;
              font-size: 16px;
              display: inline-block;
              vertical-align: middle;
            }
          }
        }
      }

      &__date {
        > div {
          div {
            display: flex;
            flex-direction: row-reverse;
            column-gap: 8px;
          }
        }
      }
      .group__filter {
        display: flex;
        align-items: center;
        gap: 16px;
        > div {
          background: #f5fdff !important;
          border-radius: 6px;
        }
      }
      .btn__print {
        background: none;
        div > button {
          width: 167px;
          background: none;
          border: 1px solid var(--color-primary-1);
          column-gap: 10px !important;
          color: var(--color-primary-1);
        }
      }
    }

    ul:nth-child(3) {
      display: flex;
      justify-content: end;
      margin-top: 24px;
    }
    .isOpen {
      background: var(--color-primary-2) !important;
    }
    .threeDot {
      cursor: pointer;
      border-radius: 4px;
      width: 22px;
      height: 24px;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
    }
  }

  .text__name__container {
    max-width: 100px;
    min-width: 100px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .view_detail {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    color: #1890FF;
    cursor: pointer;
    word-break: keep-all;
  }
}

.printqrcode__btn__container {
  flex: 1;
  background: none !important;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.list__qrcode {
  position: absolute;
  z-index: -2;
  top: 0;
  display: flex;
  width: 794px;
  flex-wrap: wrap;
  margin-top: 38px;
  > div {
    width: calc(794px / 5);
    height: calc(1123px / 7);
    display: flex;
    align-items: center;
    // justify-content: center;
    flex-direction: column;
    > p {
      width: calc(794px / 5 - 20px);
      word-break: break-all;
      text-align: center;
    }
  }
}

.stafficon {
  margin-bottom: 3px;
}

.text {
  max-width: 120px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.printqrcode__btn {
  margin-left: auto;
  width: 170px !important;
  font-size: 16px;
  font-weight: 500;
  > div {
    margin-right: 8px;
  }
}

.pagination {
  display: flex;
  justify-content: flex-end;
  margin-top: 16px;
}

@media only screen and (max-width: 1000px) {
  .contract_person {
    &__header {
      &__search {
        &__input {
          width: 100%;
          min-width: 200px;
        }
      }
    }
  }
}
