.detail__container {
  padding: 24px 40px;
  height: 643px;
  overflow-y: auto;

  &__col {
    display: flex;
    flex-direction: column;
    gap: 24px;

    .title {
      font-weight: 700;
      line-height: 22px;
      display: flex;
      align-items: center;
      color: #434343;
    }
  }

  > div:first-child {
    margin-bottom: 24px;
  }
}

.modal__body {
  padding: 0 0 24px 0;
}

