.empty__wrap {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 80px;
  padding: 80px 24px;
  .empty {
    text-align: center;
    img {
      margin-bottom: 28px;
    }
    h2 {
      font-weight: 700;
      font-size: 32px;
      margin-bottom: 0px;
      color: #595959;
    }
    p {
      font-size: 16px;
      color: #595959;
    }
  }
}
