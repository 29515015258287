.operation {
  &__header {
    display: flex;
    align-items: center;
    width: 100%;
    column-gap: 16px;
    row-gap: 10px;
    padding: 8px 0;
    flex-wrap: wrap;

    &__search {
      display: flex;
      gap: 24px;
      align-items: center;

      &__input {
        width: 295px;
        height: 36px;
        border: 1px solid #bfbfbf;
        border-radius: 20px;
        background: #ffffff;
        padding: 6px 12px;
      }

      &__btn {
        width: 36px !important;
        height: 36px !important;
        border-radius: 8px !important;
        padding: 0;
      }
    }

    &__button {
      width: 161px !important;
      height: 40px !important;
      border: 1px solid #237804;
      border-radius: 20px;
    }

    &__buttonRefetch {
      width: 183px !important;
      height: 40px !important;
      border: 1px solid #237804;
      border-radius: 20px;
    }
  }

  &__content {
    max-width: 1280px;
    padding: 0 24px;
    margin: 0 auto;

    &__alert {
      display: flex;
      align-items: flex-start;
      gap: 7px;

      &__icon > svg {
        width: 17px;
        height: 17px;
      }
    }
    .empty__results {
      padding: 40px 24px;
    }

    &__filter {
      display: flex;
      row-gap: 10px;
      flex-wrap: wrap;
      margin: 24px 0;
      column-gap: 16px;
      padding: 0 24px;
      max-width: 1280px;
      margin: 24px auto;
      justify-content: space-between;
      flex-wrap: wrap;

      &__select {
        > div {
          background: #f5fdff !important;
          border-color: transparent !important;
          box-shadow: none !important;
        }
      }

      > div div {
        > span:nth-child(2) {
          align-items: center;
          flex: auto;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;

          .icon__filter {
            margin-right: 8px;
            margin-bottom: 3px;
            font-size: 16px;
            display: inline-block;
            vertical-align: middle;
          }
        }
      }

      &__date {
        width: 163px;

        > div > span {
          margin-left: 0;
        }
      }

      &__group {
        display: flex;
        align-items: center;
        gap: 16px;
        margin-bottom: 3px;

        > div:last-child {
          background: none;
          flex-wrap: wrap;
        }

        > div:nth-child(1),div:nth-child(2) {
          background: #f5fdff;
          border-radius: 8px;

          > div {
            background: #f5fdff !important;
            border-color: transparent !important;
            box-shadow: none !important;
          }
        }
      }
    }

    ul:nth-child(3) {
      display: flex;
      justify-content: end;
      margin-top: 24px;
    }

    .isOpen {
      background: var(--color-primary-2) !important;
    }

    .threeDot {
      cursor: pointer;
      border-radius: 4px;
      width: 22px;
      height: 24px;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
    }
  }
}

.text {
  max-width: 120px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.pagination {
  display: flex;
  justify-content: flex-end;
  margin-top: 16px;
}
