.summary {
  &__form__item {
    > div {
      > div {
        padding: 0 0 4px !important;
        label {
          font-weight: 700;
        }
      }
    }
  }
  &__wrap {
    display: flex;
    justify-content: center;
    padding: 50px 32px;
  }
  &__content {
    max-width: 365px;
    width: 100%;
    min-width: 200px;
  }
  &__select {
    height: 40px;
    > div {
      height: 40px !important;
    }
  }
  &__btn {
    width: 100%;
    > div {
      height: 56px;
      width: 100%;
      margin-bottom: 24px;
    }
    button {
      width: 100%;
      height: 48px;
      border-radius: 28px;
      > svg {
        margin-right: 8px;
      }
    }
  }
  &__filter__month {
    width: 100%;
    display: flex;
    margin-bottom: 24px;
    justify-content: space-between;
    > div {
      width: calc(50% - 6px);
      > div {
        width: 100%;
        border: 1px solid #f5fdff !important;
        background: #f5fdff;
        box-shadow: none !important;
        height: 40px;
      }
    }
  }
}

.summary__filter__month {
  > div {
    background: #f5fdff !important;
    border-radius: 20px;
    > div {
      background: #f5fdff !important;
      border-radius: 20px !important;
    }
  }
}
