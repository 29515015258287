.wrap__page {
  height: 100vh;
  background-image: url('../../assets/images/element.png');
  background-repeat: no-repeat;
  background-position: right bottom;

  &__content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }
  .heading__notfound {
    font-size: 34px;
    color: #237804;
  }
  .description {
    color: #237804;
    font-size: 18px;
    font-weight: 500;
    text-align: center;
  }
  .image {
    width: 300px;
    height: 300px;
    object-fit: cover;
    img {
      width: 100%;
      height: 100%;
    }
  }
}

.description {
  max-width: 800px;
  margin: 24px auto 24px;
}

.back__to__home {
  box-shadow: 0px 6px 16px rgba(35, 120, 4, 0.3);
  background-color: #237804;
  color: #ffff;
  border: none;
  &:hover {
    border: 1px solid #237804;
    color: #237804;
    outline: none;
  }
}
.page__content {
  background: linear-gradient(
    156.45deg,
    #bdf0f9 7.32%,
    #b8eef8 12.01%,
    #abe9f4 17.34%,
    #94e0ee 23%,
    #2f6e96 76.21%,
    #39507d 97.01%,
    #403e6e 114.78%,
    #423768 127.43%
  );
}
