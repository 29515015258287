.container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.img__container {
  margin: 24px;
  border: 2px solid var(--color-gray);
  padding: 15px;
  border-radius: 20px;
  overflow: hidden;
  position: relative;
  > svg {
    width: 100% !important;
    height: 100% !important;
  }
}

.clone__qrcode {
  position: absolute;
  z-index: -2;
  top: 20px;
  left: 20px;
}

.form__footer {
  background: #eaf3f6;
  border-radius: 0 0 20px 20px;
  display: flex;
  gap: 8px 16px;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  width: 100%;
  padding: 24px;
  > div {
    flex: 1;
    > button {
      width: 100%;
      min-width: 112px;
    }
  }
}
