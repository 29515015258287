.invoice__deatail {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 14px;
    
    &__note {
        font-size: 10px;
        text-align: center;
        line-height: 150%;
    }
}

.invoice__deatail__block:first-child {
    margin-bottom: 4px;
    padding-bottom: 4px;
}

.invoice__deatail__block:last-child {
    margin: 0;
    padding: 0;
    border: none
}

.invoice__deatail__block {
    width: 100%;
    border-bottom: 1px solid #8C8C8C;
    margin-bottom: 4px;
    padding-bottom: 4px;
    display: flex;
    flex-direction: column;
    gap: 4px;

    &__title {
        background: #B7EB8F;
        text-align: center;
        font-weight: 400;
        font-size: 10px;
        line-height: 150%;
        color: #27272A;
        padding: 4px 0px;
    }

    &__row {
        width: 100%;
        flex-wrap: nowrap;
        gap: 24px;
        font-size: 10px;

        &__title {
            flex: 1;
            font-style: normal;
            font-weight: 400;
            font-size: 10px;
            line-height: 150%;
            display: flex;
            align-items: center;
            color: #27272A;
            word-wrap: break-word;
            white-space: normal;
            margin: 0;
        }

        &__content {
            width: calc(100% - 118px);
            font-style: normal;
            font-weight: 700;
            font-size: 10px;
            line-height: 150%;
            color: #27272A;
            word-break: break-word;
        }

        &__note {
            font-size: 10px;
            line-height: 150%;
        }
    }
}

.page__content {
    padding: 24px 0;
    border-top: 1px solid var(--color-neutral-6);
    border-bottom: 1px solid var(--color-neutral-6);
}

.info__total_left {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.info__total__detail {
    display: flex;
    align-items: center;
    gap: 24px;
    line-height: 1.5;

    &__title {
        flex: 1;
    }

    &__content {
        width: calc(100% - 118px);
        font-weight: 700;
        word-break: break-word;
    }
}

.info__total_right {
    display: flex;
    justify-content: center;
    align-items: center;
    line-height: 1.5;
}

.text {
    font-weight: 700;
    font-size: 10px;
    line-height: 150%;
    color: #27272A;
    line-height: 150%;
    flex: 1;
    max-width: 140px;
}

.unit {
    color: #237804;
    font-weight: 700;
    line-height: 150%;
    font-weight: 700;
    width: 18px;
    text-align: left;
}
