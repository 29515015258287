.terminal {
  &__header {
    gap: 30px;
    align-items: center;
    &__search__input {
      width: 295px;
      height: 36px;
      border: 1px solid #bfbfbf;
      border-radius: 20px;
      background: #ffffff;
      padding: 6px 12px;
    }
    &__notification {
      display: flex;
      cursor: pointer;
    }
  }
  &__filter {
    margin: 24px 0;
    column-gap: 16px;
    padding: 0 24px;
    max-width: 1280px;
    margin: 24px auto;
    justify-content: space-between;
    &__line {
      width: 14px;
      height: 2px;
      background: #595959;
    }
    &__select {
      > div {
        background: transparent !important;
        border-color: transparent !important;
        box-shadow: none !important;
      }
    }
    > div div {
      > span:nth-child(2) {
        align-items: center;
        flex: auto;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        .icon__filter {
          margin-right: 8px;
          margin-bottom: 3px;
          font-size: 16px;
          display: inline-block;
          vertical-align: middle;
        }
      }
    }
    .group__filter {
      display: flex;
      align-items: center;
      gap: 16px;
      > div {
        background: #f5fdff;
        border-radius: 8px;
      }
    }
  }
  &__table {
    max-width: 1280px;
    height: 100%;
    padding: 24px 24px 0 24px;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    &__pagination {
      display: flex;
      justify-content: flex-end;
      margin-top: 16px;
    }
  }
}
.btn__add {
  > div {
    margin-right: 12px;
  }
}
.terminal__text {
  max-width: 300px;
  min-width: 100px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
