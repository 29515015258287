.form__container {
  padding: 16px 24px 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;

  .form__content {
    width: 400px;
  }
}

.form__name {
  flex: 1;
  margin-left: 24px;
  display: flex;
  flex-wrap: wrap;
  column-gap: 40px;

  >div {
    flex: 1;
  }

  >div>div>div:first-child {
    padding: 0 0 4px;
    font-weight: 700;
    font-size: 14px;
  }
}

.form__item__placeholder {
  font-size: 14px;
  font-weight: 400;
  color: #bfbfbf;
}

.form__content {
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  gap: 8px;
  margin: 0;

  .Modal_form__item {
    min-width: 250px;
    margin-bottom: 0;
  }

  .form__item--full {
    min-width: 100%;
    margin: 0;
    padding-bottom: 0;

    input {
      padding: 8px;
      height: 37px;
      border-radius: 8px;
    }
  }

  >div {
    font-size: 14px;
    font-weight: 700;
    flex: 1;
    margin-bottom: 0;

    .select {
      >div {
        border-radius: 8px !important;
      }
    }

    >div>div {
      padding-bottom: 0 0 4px;
    }
  }
}

.form__footer {
  background: #eaf3f6;
  border-radius: 0 0 20px 20px;
  padding: 24px;
  display: flex;
  gap: 16px;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  width: 100%;

  >div {
    flex: 1;
  }

  button {
    width: 100%;
    border-radius: 28px;
    height: 54px;
  }
}

.confirm {
  >div>div>div>div>div:first-child {
    display: flex;
    align-items: center;
  }
  >div>div>div>div>div {
    margin-top: 0px !important;
    padding: 16px;
  }
}
.alertContent {
  text-align: center;
  padding: 8px;
}

@media only screen and (max-width: 500px) {
  .form {
    &__top {
      flex-direction: column;
      align-items: center;
    }

    &__name {
      margin: 0;
    }

    &__bottom {
      margin: 0;
    }
  }
}

@media only screen and (max-width: 815px) {
  .form__name:nth-child(3) {
    margin-left: 0;
  }
}