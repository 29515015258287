.home {
  .home__header {
    display: flex;
    flex: 1;
    justify-content: center !important;
    flex-wrap: wrap;

    width: 100%;
    margin-right: 72px;
    column-gap: 120px;
    &__item {
      display: flex;
      flex-direction: column;
      > span:first-child {
        font-weight: 600;
        font-size: 16px;
        line-height: 24px;
      }
      > span:nth-child(2) {
        font-weight: 600;
        font-size: 64px;
        color: var(--color-primary-1);
        line-height: 70px;
      }
    }
  }
}

.home__main {
  padding: 60px;
  padding: 60px;
  max-width: 1280px;
  margin: auto;
  &__row {
    display: flex;
    justify-content: center;
  }
}
.home__item {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 32px 64px;
  gap: 8px;
  justify-content: space-between;
  min-width: 248px;
  height: 187px;
  cursor: pointer;

  background: #ffffff;
  border-radius: 8px;
  &__icon {
    font-size: 60px;
    path {
      fill: var(--color-dark-gray);
    }
  }
  p {
    font-weight: 600;
    font-size: 24px;
    margin: 0;
    color: var(--color-dark-gray);
  }
  &:hover {
    filter: brightness(0.9);
  }
}

@media (max-width: 810px) {
  .home__header {
    column-gap: 5vw;
    margin: 0 10%;
    margin-right: 0;
    &__item {
      min-width: 100px;
    }
  }
  .home__header__item > span:first-child {
    font-size: 12px;
  }
  .home__header__item > span:nth-child(2) {
    font-size: 32px;
  }
}

@media only screen and (max-width: 600px) {
  .home {
    .home__header {
      margin-right: 0;
    }
    > div:first-child {
      min-height: 120px !important;
    }
    &__main {
      padding: 30px;
    }
  }
}
