.form__footer {
  background: #eaf3f6;
  border-radius: 0 0 20px 20px;
  padding: 24px;
  display: flex;
  gap: 16px;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  width: 100%;
  > div {
    flex: 1;
    max-width: 328px;
  }
  button {
    width: 100%;
  }
}
.figure {
  display: flex;
  justify-content: center;
  margin-top: 24px;
}

.text__confirm {
  font-weight: 400;
  font-size: 18px;
  color: #808089;
  text-align: center;
}
