.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  min-width: 200px;
  min-height: 200px;
}

.img__container {
  margin: 24px;
  border: 2px solid var(--color-gray);
  padding: 15px;
  border-radius: 20px;
  overflow: hidden;
  position: relative;
  > svg {
    width: 100% !important;
    height: 100% !important;
  }
}

.figure {
  max-width: 350px;

  margin: 20px;
  > img {
    aspect-ratio: 1 / 1;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.loading {
  border-radius: 20px !important;
}
