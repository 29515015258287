.settingForm__item {
  .ant-select-selector {
    border-radius: 6px !important;
    height: 40px;
  }

  .ant-input-number {
    border-radius: 6px !important;
    height: 40px;
  }
}
