.header__icon {
  display: inline-block;
}
.header__menu__antd__admin {
  > li:first-child {
    display: none;
  }
}
.header__menu ul li {
  font-weight: 300;
  opacity: 0.5;
  color: var(--color-daybreak-blue) !important;
}
.header__menu > ul > li > div:first-child > span {
  font-weight: 300;
  opacity: 0.5;
  color: var(--color-daybreak-blue) !important;
}
.submenu__big > div,
.submenu__big > div > i,
.submenu__small > div > span,
.submenu__small > div > i {
  color: #fff !important;
}
.header__menu .submenu__big > div > span,
.header__menu .submenu__big > div > div > svg {
  opacity: 1 !important;
}
