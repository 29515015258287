.wrapper__auth {
  background: linear-gradient(
    156.45deg,
    #bdf0f9 7.32%,
    #b8eef8 12.01%,
    #abe9f4 17.34%,
    #94e0ee 23%,
    #2f6e96 76.21%,
    #39507d 97.01%,
    #403e6e 114.78%,
    #423768 127.43%
  );
}
.title {
  > h1 {
    font-size: 36px;
    font-weight: bold;
    text-transform: uppercase;
  }
}
.auth__page {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  background-image: url('../../assets/images/element.png');
  background-repeat: no-repeat;
  background-position: right bottom;
  min-height: 100vh;
  padding: 24px;
  > div {
    max-width: 376px;
    width: 100%;
  }
}
