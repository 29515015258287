.form {
  &__wrap {
    display: flex;
    justify-content: center;
    flex-direction: column;
    padding: 24px 24px 0;
    width: 100%;
  }
  &__item {
    display: flex;
    flex-direction: column;
    margin-bottom: 24px;
    min-width: 160px;
    width: 100%;
    label {
      font-size: 14px;
      font-weight: 700;
    }
  }
  &__btn {
    &__wrap {
      padding: 24px;
      display: flex;
      gap: 8px;
      flex-wrap: wrap;
      background-color: #eaf3f6;
      justify-content: space-between;
      border-radius: 0 0 24px 24px;
      > div {
        flex: 1;
      }

      button {
        width: 100%;
        min-width: 150px;
      }
    }
  }
}
