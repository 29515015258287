.ant-btn-ghost {
  border: 1px solid #237804;
  color: #237804;
  background: #ffffff;
  // &:hover {
  //   background: #ffffff;
  // }
  // &:focus {
  //   background: #ffffff;
  // }
}

.ant-btn-primary {
  box-shadow: 0px 6px 16px rgba(35, 120, 4, 0.3);
}

// header menu selected
.menu--selected {
  .ant-menu-item-selected {
    a {
      opacity: 1;
      font-weight: 400;
    }
    svg {
      opacity: 1;
    }
  }
}

.ant-menu {
  a {
    color: #e6f7ff;
  }
}
.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
  background-color: #4281b5;
}
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-selected {
  color: #e6f7ff;
}

.ant-menu-submenu-popup > .ant-menu {
  background-color: #375783;
}

.ant-menu-overflow-item-rest {
  color: #e6f7ff;
}

.ant-modal-body {
  padding: 0 !important;
}

.ant-modal-content {
  border-radius: 24px;
}

.ant-modal-header {
  border-radius: 24px 24px 0 0;
  padding: 24px;
}

.ant-modal-close {
  top: 8px;
  right: 10px;
}
.ant-modal-title {
  font-weight: 700;
  font-size: 24px;
}
.ant-modal-close-x {
  display: flex;
  justify-content: center;
  align-items: center;
  > span {
    color: #000000;
    background: #d9d9d9;
    border-radius: 8px;
    width: 40px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item:hover::after,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu:hover::after,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-active::after,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-active::after,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-open::after,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-open::after,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-selected::after,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-selected::after {
  border-bottom: 2px solid #e6f7ff;
}

//input
.ant-input-affix-wrapper > input.ant-input {
  padding: 2px !important;
}

//table
.ant-table {
  border-radius: 8px;
  table {
    border-radius: 8px 8px 0 0;
    width: 100% !important;
    min-width: 1024px !important;

    thead[class*="ant-table-thead"] th {
      word-break: keep-all;
      &:before {
        display: none;
      }
    }
  }
}

.ant-table-thead
  > tr
  > th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before {
  background-color: none;
}

::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #e0e8eb;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: rgb(211, 211, 211);
  border-radius: 20px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #bbb;
  border-radius: 20px;
}

/* max content */
.main-content {
  max-width: 1280px;
  margin: auto;
}

// menu mobile
.ant-menu-item a {
  &:hover {
    color: #fff !important;
  }
}

// input password
.ant-input-affix-wrapper > input.ant-input {
  padding: 0px !important;
}

//remove before required
.ant-form-item-required::before {
  display: none !important;
}

//remove background color input auto fill
input:-webkit-autofill {
  transition: background-color 5000s ease-in-out 0s;
}

//text input eror
.ant-form-item-explain-error {
  font-size: 12px;
}

//datepicker
.datepicker__custom {
  .ant-picker-suffix {
    pointer-events: all !important;
  }
}

//hidden checkall table
th.ant-table-cell.ant-table-selection-column {
  .ant-table-selection {
    visibility: hidden;
  }
}

span.ant-select-selection-placeholder {
  font-weight: 400;
}

//message error
.ant-form-item-explain-error {
  font-size: 12px;
  font-weight: 600;
}
// show toast
.ant-notification-notice-description {
  display: flex;
  align-items: center;
}

//tabs
#tab-1 {
  .ant-tabs-nav::before {
    position: absolute;
    right: 0;
    left: 0;
    content: "";
    border-bottom: 2px solid #4e9a94;
    bottom: -2px;
  }
  .ant-tabs-tab {
    padding: 0;
    border: none;
  }
}
body {
  overflow: hidden; /* Hide scrollbars */
}

.select__group span.ant-select-selection-placeholder {
  color: #595959 !important;
}

.select__option {
  .ant-select-item-option-content {
    display: flex;
    justify-content: space-between;
  }
}

//don't hidden pagesize pagination
@media only screen and (max-width: 576px) {
  .ant-pagination-options {
    display: inline !important;
  }
}

.ant-pagination {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
  row-gap: 4px;
}

.modalUser {
  .ant-form-item {
    margin-bottom: 12px;
  }
  .ant-select-selection-item {
    margin-top: 0px;
    margin-right: 0px;
    margin-bottom: 0px;
  }
  .ant-select-multiple.ant-select-sm .ant-select-selection-item {
    height: 18px;
    line-height: 18px;
  }
  .ant-select-multiple.ant-select-sm .ant-select-selection-search-input,
  .ant-select-multiple.ant-select-sm .ant-select-selection-search-mirror {
    height: 18px;
    line-height: 10px;
  }
  .ant-select-selection-search {
    height: 18px !important;
  }
}
