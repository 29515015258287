:root {
  --color-primary: #237804;
  --color-primary-blur: #dae7e4;
  --color-primary-1: #389e0d;
  --color-primary-2: #95de64;
  --color-primary-3: #237804;
  --color-gray: #d9d9d9;
  --color-daybreak-blue: #e6f7ff;
  --color-dark-gray: #595959;
  --color-neutral-6: #bfbfbf;
  --color-neutral-9: #434343;
}
