.manager {
  &__header {
    display: flex;
    align-items: center;
    width: 100%;
    column-gap: 16px;
    row-gap: 10px;
    padding: 8px 0;
    flex-wrap: wrap;
    &__search {
      display: flex;
      gap: 24px;
      align-items: center;
      &__input {
        width: 295px;
        height: 36px;
        border: 1px solid #bfbfbf;
        border-radius: 20px;
        background: #ffffff;
        padding: 6px 12px;
      }
    }
    &__button {
      width: 166px !important;
      height: 38px !important;
      column-gap: 8px;
      border-radius: 20px;

      box-shadow: 0px 6px 16px rgba(35, 120, 4, 0.3);
      background: var(--color-primary-3);
      color: #ffffff;
    }
  }
  .addManager__btn {
    width: 192px !important;
    font-size: 16px;
    font-weight: 500;
    > div {
      margin-right: 8px;
    }
  }
  .cardManager__container {
    margin: 24px;
    width: 100%;
  }
  .cardManager__wrap {
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 1280px;
    padding: 0 18px;
    margin: 0 auto;
    .btn__add {
      > div {
        margin-right: 12px;
      }
    }
  }
  .page__container {
    width: 100%;
  }

  .pagination {
    width: 100%;
    display: flex;
    justify-content: end;
  }

  .control__wrap {
    column-gap: 16px;

    width: 100%;
    > div:first-child {
      max-width: 300px;
      width: 100%;
    }
  }
}

.pagination__container {
  display: flex;
  justify-content: end;
  width: 100%;
}
