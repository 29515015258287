.input {
  width: 100%;

  > input {
    background: #ffffff;
    border: 1px solid #dddde3;
    border-radius: 20px;
    padding: 0.5em;
    padding: 7px 12px;
  }
}
