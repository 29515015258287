.forgot {
  &__title {
    > h1 {
      font-size: 36px;
      font-weight: bold;
      text-transform: uppercase;
      white-space: nowrap;
    }
  }
}
.forgot__page {
  height: auto;
  background: rgba(255, 255, 255, 0.9);
  padding: 24px 24px 36px 24px;
  border-radius: 16px;
  h1 {
    text-align: center;
    font-weight: 600;
    color: #237804;
    font-size: 24px;
  }
  .password__page {
    display: flex;
    flex-direction: column;
    .password__form {
      display: flex;
      flex-direction: column;
      &__item {
        display: flex;
        flex-direction: column;
        margin-bottom: 24px;
        div > div {
          > label {
            margin-bottom: 4px;
            font-size: 14px;
            font-weight: 700;
          }
        }
        > input {
          outline: none;
          border: none;
          padding: 9.5px 9.5px 8px 16px;
          border-radius: 20px;
        }
        > a {
          .test__border {
            border-bottom: 1px solid #000;
          }
        }
      }
      > a {
        > span {
          border-bottom: 1px solid black;
          color: black;
          cursor: pointer;
        }
      }
    }
    .form__btnGroup {
      width: 100%;
      > div {
        width: 100%;
      }
      .btn__reset {
        background-color: var(--color-primary);
        color: #ffff;
        box-shadow: 0px 6px 16px rgba(35, 120, 4, 0.3);
        width: 100%;
      }
      .btn__back__login {
        border: 1px solid #237804;
        background-color: transparent;
        color: #237804;
        margin-top: 16px;
        outline: none;
        width: 100%;
      }
    }
  }
}
