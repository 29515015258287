.addstaff__container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  height: 100%;
  padding: 8px 16px;
  margin: 0 auto 24px auto;

  background: rgba(255, 255, 255, 0.9);
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.06);
  border-radius: 8px;

  line-height: 24px;
  text-align: left;
}

.test {
  display: flex;
  column-gap: 24px;
  align-items: center;
}

.top {
  display: flex;
  width: 100%;
  padding-bottom: 8px;
  border-bottom: 1px solid #d9d9d9;
  .userInfor {
    display: flex;
    flex-direction: column;
    padding-left: 8px;
    width: calc(100% - 90px);
    white-space: nowrap;
    > span:first-child {
      font-size: 18px;
      font-weight: 600;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    > span:nth-child(2) {
      font-size: 16px;
      font-weight: 400;
    }
  }
  .dropdown {
    margin: 0 4px 28px auto;
  }
}

.bottom {
  display: flex;
  flex-direction: column;
  width: 100%;
  row-gap: 10px;
  .building {
    &__more {
      border-radius: 50% !important;
      min-width: 20px;
      padding: 0 2px !important;
      font-size: 12px;
      text-align: center;
      flex: none !important;
    }
    > span:not(:first-child) {
      flex: 1;
    }
  }
  .building,
  .status {
    display: flex;
    > span:first-child {
      font-weight: 600;
      text-align: left;
      display: inline-block;
      width: 64px;
    }
    > span:not(:first-child) {
      background: var(--color-gray);
      margin-right: 8px;
      display: inline-block;
      padding: 0px 8px;
      border-radius: 2px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
}

.status__active {
  > span:nth-child(2) {
    background: #95de64 !important;
  }
}

.isopen {
  background: var(--color-primary-2) !important;
}

.threedot {
  border-radius: 4px;
  width: 22px;
  height: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.no__building {
  background: none !important;
}

.checkbox__wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  align-items: center;
  height: 100%;
  margin-right: 4px;
}

.checked {
  background: var(--color-primary-blur) !important;
}
