.item {
  padding: 16px;
  gap: 24px;
  display: flex;
  width: calc(50% - 12px);
  background: #ffffff;
  border-radius: 8px;
  flex-wrap: wrap;
  &__wrap {
    flex: 1;
    min-width: 220px;
  }
  &__img {
    width: 150px;
    height: 160px;
    border-radius: 8px;
    object-fit: cover;
    margin: 0 auto;
  }
  &__title {
    font-weight: 700;
    font-size: 20px;
    line-height: 24px;
    margin: 0;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;

    &__text {
      color: var(--color-primary);
    }
  }
  &__sub__title {
    font-weight: 700;
    font-size: 16px;
    overflow: hidden;
  }
  &__info {
    flex: 1;
    display: flex;
    span:first-child {
      width: 85px;
      display: inline-block;
      font-weight: 600;
      font-size: 14px;
    }
    span:nth-child(2) {
      flex: 1;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }
}
.wrap__iteminfo {
  display: flex;
  flex-direction: column;
}
.hr {
  border: 1px solid var(--color-gray);
  margin: 8px 0;
}

@media (max-width: 800px) {
  .item {
    width: 100%;
  }
}
