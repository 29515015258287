.uploadButton {
  > span {
    font-weight: 700;
    font-size: 14px;
    margin-bottom: 8px;
    display: inline-block;
  }
}

.uploadbutton__container {
  width: 120px;
  height: 120px;
  border: 1px dashed #8c8c8c;
  border-radius: 60px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: #237804;
  // &:hover {
  //   border: 1px dashed #237804;
  // }
  > span {
    margin-bottom: 3px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.delete__logo {
  position: absolute;
  top: 0;
  right: 10px;
  background-color: #fff;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  border: 1px solid #ff4d4f;
  display: flex;
  justify-content: center;
  align-items: center;
  > span {
    color: #ff4d4f;
    font-size: 12px;
  }
}

.upload__container {
  cursor: default;
  background-color: transparent;
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-right: 18px;
  > div {
    background-color: transparent !important;
    border: none !important;
  }
}

.figure__cusor {
  cursor: default;
}

.border__right {
  border-right: 1px solid #bfbfbf;
}

.changelogo__overlay {
  display: none;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: absolute;
  border-radius: 50%;
  top: 0;
  left: 0;
  width: 100%;
  height: 120px;
  background: #0000006d;
  > p {
    font-weight: 400;
    font-size: 12px;
    color: #fff;
  }
}

.img__container {
  position: relative;
  width: 120px;
  border-radius: 60px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: #237804;
  &:hover {
    .changelogo__overlay {
      display: flex !important;
    }
  }
  > span {
    font-weight: 700;
    font-size: 14px;
    margin-bottom: 8px;
    display: inline-block;
    color: #27272a;
  }
  figure {
    height: 120px;
    width: 120px;
    position: relative;
    margin: 0;
    > img {
      object-fit: cover;
      width: inherit;
      height: 120px;
      border-radius: 50%;
    }
  }
}
