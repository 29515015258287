.login {
  &__title {
    > h1 {
      font-size: 36px;
      font-weight: bold;
      text-transform: uppercase;
      white-space: nowrap;
    }
  }
  &__page {
    height: auto;
    background: rgba(255, 255, 255, 0.9);
    padding: 24px 24px 36px 24px;
    border-radius: 16px;
  }
  &__header {
    display: flex;
    flex-direction: column;
    &__text {
      text-align: center;
      font-size: 24px;
      font-weight: 600;
      color: #135200;
      font-size: 24px;
    }
    &__form {
      display: flex;
      flex-direction: column;
      .form__item {
        display: flex;
        flex-direction: column;
        margin-bottom: 24px;
        > div > div:first-child {
          color: #27272a;
          font-weight: 700;
          font-size: 14px;
          padding: 0 0 4px;
        }
        > input {
          outline: none;
          border: none;
          padding: 9.5px 9.5px 8px 16px;
          border-radius: 20px;
        }
        > a {
          .test__border {
            border-bottom: 1px solid #000;
          }
        }
      }
      > a {
        > span {
          border-bottom: 1px solid black;
          color: black;
          cursor: pointer;
        }
        span:hover {
          color: #237804;
          border-bottom: 1px solid #237804;
          transition: all 0.2s;
        }
      }
    }
    div:last-child {
      width: 100%;
      .btn__login {
        color: #ffff;
        box-shadow: 0px 6px 16px rgba(35, 120, 4, 0.3);
        background-color: var(--color-primary);

        width: 100%;
        margin-top: 48px;
        outline: none;
        border: none;
      }
    }
  }
}
