.notification {
  display: flex;
  cursor: pointer;
  > div {
    display: flex;
  }
  > div > span > sup {
    box-shadow: none;
  }
  @media only screen and (max-width: 1088px) {
    width: 100%;
    justify-content: flex-end;
  }
}
