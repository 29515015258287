.price {

  &__header {
    display: flex;
    align-items: center;
    width: 100%;
    column-gap: 16px;
    row-gap: 10px;
    padding: 8px 0;
    flex-wrap: wrap;

    &__search {
      display: flex;
      gap: 24px;
      align-items: center;

      &__input {
        width: 295px;
        height: 36px;
        border: 1px solid #bfbfbf;
        border-radius: 20px;
        background: #ffffff;
        padding: 6px 12px;
      }
    }

    &__button {
      width: 140px !important;
      height: 38px !important;
      border-radius: 20px;
      box-shadow: 0px 6px 16px rgba(35, 120, 4, 0.3);
      background: var(--color-primary-3);
      color: #ffffff;
    }
  }

  &__addRule__btn {
    width: 140px !important;
    font-size: 16px;
    font-weight: 500;

    > div {
      margin-right: 8px;
    }
  }

  &__tabs {
    max-width: 1280px;
    height: 100%;
    min-height: 650px;
    padding: 24px 24px;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    &__pagination {
      display: flex;
      justify-content: flex-end;
      margin-top: 16px;
    }

    &__filter {
      column-gap: 16px;
      justify-content: space-between;

      &__line {
        width: 14px;
        height: 2px;
        background: #595959;
      }

      &__select {

        > div {
          background: transparent !important;
          border-color: transparent !important;
          box-shadow: none !important;
        }
      }

      > div div {

        > span:nth-child(2) {
          align-items: center;
          flex: auto;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;

          .icon__filter {
            margin-right: 8px;
            margin-bottom: 3px;
            font-size: 16px;
            display: inline-block;
            vertical-align: middle;
          }
        }
      }

      .group__filter {
        display: flex;
        align-items: center;
        gap: 16px;
        margin-bottom: 3px;

        > div:last-child {
          background: none;
        }

        > div:nth-child(1),
        div:nth-child(2) {
          background: #f5fdff;
          border-radius: 8px;
        }
      }
    }
  }

  &__panel {
    padding: 8px 40px;
    border-radius: 8px 8px 0 0;
    font-size: 16px;
    line-height: 24px;
  }

  .pageContent {
    padding: 18px 24px 0;
    max-width: 1280px;
    margin: 0 auto;

    .tabItem_label {
      background: #d9d9d9;
      border-radius: 8px 8px 0px 0px;
      padding: 8px 32px;
      height: 38px;
    }
  }

  .empty__results {
    padding: 40px 24px;
  }

  .formula {

    &__pagination {
      margin-top: 16px;
      text-align: right;
      width: 100%;
      display: flex;
      justify-content: flex-end;
    }
  }
}

.pagination__container {
  display: flex;
  justify-content: end;
  width: 100%;
}
