.meterType {
  &__header {
    display: flex;
    align-items: center;
    width: 100%;
    column-gap: 16px;
    row-gap: 10px;
    padding: 8px 0;
    flex-wrap: wrap;

    &__search {
      display: flex;
      gap: 24px;
      align-items: center;
      &__input {
        width: 295px;
        height: 36px;
        border: 1px solid #bfbfbf;
        border-radius: 20px;
        background: #ffffff;
        padding: 6px 12px;
      }
    }

    &__button {
      width: 166px !important;
      height: 38px !important;
      column-gap: 8px;
      border-radius: 20px;
      box-shadow: 0px 6px 16px rgba(35, 120, 4, 0.3);
      background: var(--color-primary-3);
      color: #ffffff;
    }
  }

  .addMeterType__btn {
    width: 192px !important;
    font-size: 16px;
    font-weight: 500;
    >div {
      margin-right: 8px;
    }
  }

  .btn__add {
    >div {
      margin-right: 12px;
    }
  }

  .page__content {
    max-width: 1280px;
    padding: 0 24px;
    margin: 0 auto;
    .empty__results {
      padding: 40px 24px;
    }
    &__filter {
      display: flex;
      row-gap: 10px;
      flex-wrap: wrap;
      margin: 24px 0;
      column-gap: 16px;
      padding: 0 24px;
      max-width: 1280px;
      margin: 24px auto;
      justify-content: space-between;
      > div {
        border: none;
        border-radius: 8px;
        border-color: transparent !important;
        box-shadow: none !important;
        div {
          background: transparent !important;
          border-color: transparent !important;
          box-shadow: none !important;

          > span:nth-child(2) {
            display: flex;
            align-items: center;
            .icon__filter {
              margin-right: 8px;
              margin-bottom: 3px;
              font-size: 16px;
            }
          }
        }
      }
      .group__filter {
        display: flex;
        align-items: center;
        gap: 16px;
        > div {
          background: #f5fdff !important;
          border-radius: 6px;
        }
      }
    }
  }
}

.pagination {
  display: flex;
  justify-content: flex-end;
  margin-top: 16px;
}