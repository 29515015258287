.history {
    &__header {
      display: flex;
      align-items: center;
      width: 100%;
      column-gap: 16px;
      row-gap: 10px;
      padding: 8px 0;
      flex-wrap: wrap;
    }
  
    .page__content {
      max-width: 1280px;
      padding: 0 24px;
      margin: 0 auto;
      .empty__results {
        padding: 40px 24px;
      }
      &__filter {
        display: flex;
        row-gap: 10px;
        flex-wrap: wrap;
        margin: 24px 0;
        column-gap: 16px;
        padding: 0 24px;
        max-width: 1280px;
        margin: 24px auto;
        justify-content: space-between;
        > div {
          border: none;
          border-radius: 8px;
          border-color: transparent !important;
          box-shadow: none !important;
          div {
            background: transparent !important;
            border-color: transparent !important;
            box-shadow: none !important;
  
            > span:nth-child(2) {
              align-items: center;
              flex: auto;
              overflow: hidden;
              white-space: nowrap;
              text-overflow: ellipsis;
              
              .icon__filter {
                margin-right: 8px;
                margin-bottom: 3px;
                font-size: 16px;
                display: inline-block;
                vertical-align: middle;
              }
            }
          }
        }
        .group__filter {
          display: flex;
          align-items: center;
          gap: 16px;
          > div {
            background: #f5fdff !important;
            border-radius: 6px;
          }
        }
      }
    }
  }
  
  .pagination {
    display: flex;
    justify-content: flex-end;
    margin-top: 16px;
  }