.main {
  background-color: #eaf3f6;
  background-image: url('../../assets/images/background.png');
  background-position: bottom right -4px;
  background-repeat: no-repeat;
  height: 100vh;
  display: flex;
  flex-direction: column;
  &__content {
    display: flex;
    justify-content: center;
    &__body {
      width: 100%;
      overflow: auto;
    }
  }
}
