.form__container {
  padding: 24px 24px 12px 24px;
}

.form__top {
  display: flex;
  width: 100%;
  flex-wrap: wrap;
}

.form__uploadImage {
  display: flex;
  width: 160px;
}

.form__name {
  flex: 1;
  margin-left: 24px;
  display: flex;
  flex-wrap: wrap;
  column-gap: 40px;

  >div {
    flex: 1;
  }

  >div>div>div:first-child {
    padding: 0 0 4px;
    font-weight: 700;
    font-size: 14px;
  }
}

.form__item__placeholder {
  font-size: 14px;
  font-weight: 400;
  color: #bfbfbf;
}

.form__bottom {
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  column-gap: 40px;
  margin-top: 12px;

  >div {
    margin-bottom: 18px;
    font-size: 14px;
    font-weight: 700;
    flex: 1;

    >div>div {
      padding-bottom: 0 0 4px;
    }
  }
}

.form__footer {
  background: #eaf3f6;
  border-radius: 0 0 20px 20px;
  padding: 24px;
  display: flex;
  gap: 16px;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  width: 100%;

  >div {
    flex: 1;
    max-width: 328px;
  }

  button {
    width: 100%;
  }
}

.form__item--full {
  min-width: 100%;
  margin-bottom: 19px;
}

.form__item--half {
  width: 100%;
  min-width: 260px;
  margin-bottom: 19px;
}

.form__item__edit {
  position: absolute;
  right: 10px;
  top: 1px;
  right: 0;
  width: 30px;
  height: 100%;
  display: flex;
  align-items: center;
  cursor: pointer;

  svg {
    width: auto;
    height: auto;
  }
}

.confirm {
  >div>div>div>div>div:first-child {
    display: flex;
    align-items: center;
  }
  >div>div>div>div>div {
    margin-top: 0px !important;
    padding: 16px;
  }
}
.alertContent {
  text-align: center;
  padding: 8px;
}

@media only screen and (max-width: 500px) {
  .form {
    &__top {
      flex-direction: column;
      align-items: center;
    }

    &__uploadImage {
      margin: 24px 0;

      >span {
        padding: 0;
        border-right: none;
      }
    }

    &__name {
      margin: 0;
    }

    &__bottom {
      margin: 0;
    }
  }
}

@media only screen and (max-width: 815px) {
  .form__name:nth-child(3) {
    margin-left: 0;
  }

  .form__item--half {
    width: 100%;
    min-width: 280px;
  }
}