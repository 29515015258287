.detail__container {
  .month__select {
    background: #f5fdff;
    > div {
      background: #f5fdff !important;
      outline: none !important;
      border: none !important;
      box-shadow: none !important;
    }
  }
  padding: 24px;
  > div:first-child {
    margin-bottom: 24px;
  }
}

.download__meter {
  margin: 0;
  cursor: pointer;
}

.detail__filter {
  display: flex;
  gap: 16px;
  justify-content: space-between;
  align-items: center;

  > div {
    > div {
      background: #f5fdff;
      border-radius: unset;
      border: none !important;
      box-shadow: none !important;
      > div {
        width: 94px;
        flex-direction: row-reverse;
        column-gap: 8px;
      }
    }
  }
  &__month {
    display: flex;
    gap: 8px;
    background: none;
    > div {
      > div {
        box-shadow: none;
        border-radius: unset;
        border: unset;
        background: #f5fdff;
        > div {
          flex-direction: row-reverse;
          column-gap: 8px;
        }
      }
    }
  }
  &__alert {
    display: flex;
    align-items: center;
    gap: 7px;
    &__icon > div {
      display: flex;
    }
    &__icon > div svg {
      width: 17px;
      height: 17px;
    }
  }

  &__correctHistoryBtn {
    color: #389E0D;
    border: 1px solid #389E0D;
    border-radius: 20px;
    padding: 8px 16px;
    width: 144px !important;
  }
}

.text__mail__container {
  max-width: 165px;
  min-width: 100px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.pagination {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  margin-top: 12px;
}

.textDeleted {
  color: #FF4D4F;
}

.textModify {
  color: #52C41A
}
