.header__user {
  display: flex;
  align-items: center;
  gap: 20px;
  color: var(--color-daybreak-blue);
  font-weight: 300;
  width: 150px;
  justify-content: flex-end;
  &__name {
    // width: 130px;
    max-width: 130px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    > span:first-child {
      text-overflow: ellipsis;
      overflow: hidden;
      // white-space: normal;
      margin-right: 8px;
    }
  }

  &__dropdown {
    cursor: pointer;

    svg {
      vertical-align: inherit;
    }
    &__icon {
      color: var(--color-daybreak-blue);
    }
  }
}

@media screen and (max-width: 600px) {
  .header {
    &__user {
      display: none;
    }
  }
}

.header__user__name {
  // width: 100px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
