.content {
  &__wrap {
    display: flex;
    justify-content: center;
    flex-direction: column;
    padding: 24px 24px;
    width: 100%;
    &__log {
      height: 500px;
      background-color: #d9d9d9ce;
      border-radius: 8px;
      padding: 16px;
      overflow-y: scroll;
    }
  }
}