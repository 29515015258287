.controls__container {
  display: flex;
  align-items: center;
  column-gap: 24px;
}

.btn__filter {
  width: 36px !important;
  height: 36px !important;
  padding: 0 !important;
  border-radius: 8px !important;
}

.btn__add {

  > div {
    margin-right: 12px;
  }
}

.page__content__filter {
  display: flex;
  row-gap: 10px;
  align-items: center;
  flex-wrap: wrap;
  margin: 24px 0;
  column-gap: 16px;
  padding: 0 30px;
  max-width: 1280px;
  margin: 24px auto 24px auto;

  &__userType {
    display: flex;
    align-items: center;
    column-gap: 16px;
    margin-right: auto;
    
    .ant-select-selection-placeholder {
      color: #595959 !important;
    }

    .icon__filter {
      margin-right: 8px;
    }

    > div {

      > div {
        border-radius: 24px !important;

        > span:nth-child(2) {
          display: flex;
          align-items: center;

          .icon__filter {
            margin-right: 8px;
          }
        }
      }
    }
  }

  &__printQR {
    display: flex;
    align-items: center;
    column-gap: 16px;
    margin-left: auto;
  }
}

.printqrcode__btn {
  width: 170px !important;
  font-size: 16px;
  font-weight: 500;

  > div {
    margin-right: 8px;
  }
}

.addstaff__btn {
  width: 160px !important;
  height: 38px !important;
  font-size: 16px;
  font-weight: 500;

  > div {
    margin-right: 8px;
  }
}

.search {
  background: #fff;
  border-radius: 20px;
  min-width: 200px;
  width: 100%;
  height: 36px;
  border: 1px solid #bfbfbf;
  border-radius: 20px;
  padding: 6px 12px;
}

.page__container {
  width: 100%;
}

.pagination {
  margin-top: 16px;
  width: 100%;
  display: flex;
  justify-content: end;
}

.control__wrap {
  column-gap: 16px;
  width: 100%;

  > div:first-child {
    max-width: 300px;
    width: 100%;
  }
}

.page__container {

  .page__content {
    max-width: 1280px;
    padding: 0 24px;
    margin: 0 auto;

    .empty__results {
      padding: 40px 24px;
    }

    ul:nth-child(3) {
      display: flex;
      justify-content: end;
      margin-top: 24px;
    }

    .isOpen {
      background: var(--color-primary-2) !important;
    }

    .threeDot {
      cursor: pointer;
      border-radius: 4px;
      width: 22px;
      height: 24px;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
    }
  }

  table {

    a {
      font-size: 14px;
      line-height: 22px;
      text-decoration-line: underline;
      color: #1890FF;
    }
  }

  .text__name__container {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}

.printqrcode__btn__container {
  flex: 1;
  background: none !important;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.list__invoice {
  position: absolute;
  z-index: -2;
  display: block;
  gap: 16px;
  width: 794px;
  margin-top: 38px;
}

.invoice__deatail {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 16px;
  width: 327px;
  background: #F5F5F5;
  border-radius: 8px;
  margin: 0 auto;
  justify-content: flex-start;
  break-after: page;
}

.stafficon {
  margin-bottom: 3px;
}

.text {
  max-width: 120px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.printqrcode__btn {
  margin-left: auto;
  width: 170px !important;
  font-size: 16px;
  font-weight: 500;

  > div {
    margin-right: 8px;
  }
}

.pagination {
  display: flex;
  justify-content: flex-end;
  margin-top: 16px;
}

.textDeleted {
  color: #FF4D4F;
}

.textModify {
  color: #52C41A
}

